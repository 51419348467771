import React from 'react';
import { Form } from 'react-bootstrap';
import CommonHelper from '../../../helpers/common-helper';

const FormNumberInput = ({ input, showPercentage, ...props }) => {

  const handleChange = (e) => {
    const cursorPosition = e.target.selectionStart;
    let value = e.target.value.replace('%', '');
    const regex = /^-?(\d*(,\d*)?(e[+-]?\d*)?)?$/i;
    const processedValue = value.replace(',', '.');

    if ((regex.test(value) || value === '') && isInRange(processedValue)) {
      input.onChange(processedValue);
      if (props.onChange) {
        props.onChange(+processedValue);
      }
    }
    if (showPercentage && value !== '') {
      e.target.value = `${value}%`;
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  const onBlur = (e) => {
    if (e.target.value !== '') {
      e.target.value = CommonHelper.toRegularFormat(e.target.value.replace('%', ''));
    }

    input.onBlur(e);
  }

  const isInRange = (value) => {
    if (value === '') {
      return true;
    }

    if (value === '-') {
      return props.min === null || props.min === undefined || props.min < 0;
    }

    const numericValue = parseFloat(value.replace(',', '.')).toFixed(12);

    const isMinValid = !props.min || numericValue >= props.min;
    const isMaxValid = !props.max || numericValue <= props.max;

    return isMinValid && isMaxValid;
  }

  return (
    <Form.Control
      {...input}
      {...props}
      value={showPercentage && input.value !== '' ? `${input.value.toString().replace('.', ',')}%` : input.value.toString().replace('.', ',')}
      onChange={handleChange}
      onBlur={onBlur}
      type="text"
    />
  );
};

export default FormNumberInput;
